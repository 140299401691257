import logo from './logo.svg';
import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Switch,
  Route,
  Link,
  BrowserRouter,
  useLocation,
  useNavigate
} from "react-router-dom";
import { useRef } from 'react'


import Home from './pages/Home';
import Contacts from './pages/Contact';
import NotFound from './pages/NotFound';

import Header from './components/Header.jsx';
import Footer from './components/Footer.jsx';

function App() {

  // Переброска пользователя с http на https
  React.useEffect(() => {
    if (window.location.protocol === 'http:') {
      window.location.href = window.location.href.replace('http:', 'https:');
    }
  })

  // Рефы для секций, чтобы можно было получить доступ к DOM-элементам
  const sectionsRefs = {
    about: React.useRef(null),
    production: React.useRef(null),
    projects: React.useRef(null),
    equipments: React.useRef(null),
    partners: React.useRef(null)
  };
  const equipmentRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const scrollToSection = (section) => {
    if (location.pathname !== "/") {
      navigate("/", { state: { section } });
    } else {
      scrollToSectionAfterRoute(section);
    }
  };

  const scrollToSectionAfterRoute = (section) => {
    const ref = sectionsRefs[section];
    if (ref && ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - 80;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };
  React.useEffect(() => {
    if (location.state && location.state.section) {
      scrollToSection(location.state.section);
    }
  }, [location]);
  return (
    <>
      <Header scrollToSection={scrollToSection} />
      <div className="app">
        <Routes>
          <Route path="" element={<Home sectionsRefs={sectionsRefs} />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer scrollToSection={scrollToSection} />
      </div>
    </>
  );
}

export default App;
