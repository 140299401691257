import React from 'react'
import { useRef } from 'react'


function Home ({sectionsRefs}) {
  return (
    <>
    <div className="blockFirstHeadline" ref={sectionsRefs.about}>
        <h1>Строительная компания «Пирс»</h1>
        <h2>строительство берегоукрепительных портовых и промышленных сооружений</h2>
    </div>

    <div className="blockImg">
        <img className="backimg" src="/img/5.png" alt="Компания СК Пирс" />
        <div className="text-overlay">
            <div className="headline">
                <h1>Компания ООО СК "Пирс" - это</h1>
                <h2>Компания ООО СК "Пирс" имеет опыт работы по строительству берегоукрепительных, причальных сооружений, в промышленном гражданском строительстве.<br /> Одно из основных направлений компании, в настоящее время, является строительство портовых и берегоукрепительных сооружений, устройство свайных полей монолитных оснований подпорных стен, строительство промышленных объектов.</h2>
                <h1>Местоположение:</h1>
                <h2>Основная производственная база компании располагается в <em>г. Дзержинск Нижегородской области.</em></h2>
            </div>
        </div>
    </div>

    <div className="blockHeadline">
        <h1 style={{textAlign: "center"}}>Мощности</h1>
        <h2>Общая площадь земельного участка - 40027,6 м<sup>2</sup> с причальной стенкой 190 м/п.</h2>
        <h2>2550 м<sup>2</sup> производственных площадей:</h2>
        <ul>
            <li>Цех по производству плит ПДН</li>
            <li>Цех арматурный</li>
            <li>Цех по изготовлению оснастки</li>
        </ul>
        <h2>Бетонный завод производительностью 120 куб/час</h2>
    </div>

    <div className="blockImg" ref={sectionsRefs.production}>
        <div className="text-overlay">
            <div className="headline">
                <h1>Производство</h1>
                <h2>Компанией налажено производство продукции для строительных объектов:</h2>
                <ul>
                    <li>трубошпунт различных диаметров до 20 метров длинной</li>
                    <li>анкерных и откосных плит, плит дорожных ПДН 6x2</li>
                    <li>шпунтов различных типов железобетонных тавровых от 5-20 метров</li>
                    <li>производство металлоконструкций</li>
                </ul>
            </div>
        </div>
        <img className="backimg" src="/img/bimg.png" alt="Мощности" />
    </div>

    <h1 style={{margin: "60px 0px 15px 0px", textAlign: "center", fontWeight: "600", fontSize: "35px"}} ref={sectionsRefs.projects}>Выполненные работы</h1>
    <div className="blockGallery">
        <div className="gallery-card">
            <div className="text-gallery">
                <h3>2019-2020</h3>
                <h1>Строительство берегоукрепления р. Сура</h1>
                <a>Левый берег, на объекте подводного перехода <ins><em>МН АК "Транснефть"</em></ins></a><br />
                <a>Заказчик АО "СУ-7 СМТ" г. Арзамас Нижегородской области.</a>
                <h1>Производство и поставка Трубошпунта Ду 530 в количестве 350 тн</h1>
                <a>Заказчик ООО "СтройМодификация" г. Нижний Новгород</a>
            </div>
        </div>
        <div className="gallery-card">
            <img src="/img/4.png" alt="" />
        </div>
    </div>
    <div className="blockGallery">
        <div className="gallery-card">
            <div className="text-gallery">
                <h3>2021</h3>
                <h1>Строительство берегоукрепления р. Ока</h1>
                <a>Объект - "Шуховская башня"</a><br />
                <a>Заказчик <ins><em>ООО "НижегородСпецГидрострой"</em></ins> г. Нижний Новгород</a>
            </div>
        </div>
        <div className="gallery-card">
            <div className="text-gallery">
                <h3>2022</h3>
                <h1>Выполнение комплекса строительно-монтажных работ (Гидротехническое сооружение)</h1>
                <a>Объект - "Берегоукрепление р. Волга в районе г. Городца Нижегородской обл. (2-ой очереди) 360 метров"</a><br />
                <a>Заказчик <ins><em>ООО "Промстрой НН"</em></ins> г. Нижний Новгород</a>
            </div>
        </div>
    </div>

    <div className="blockHeadline" ref={sectionsRefs.equipments}>
        <h1 style={{textAlign: "center"}}>Оборудование и техника</h1>
        <h2>Компания располагает:</h2>
        <ul>
            <li>транспортным флотом</li>
            <li>плавкранами КПЛ 16-32</li>
            <li>грузовым транспортом</li>
            <li>строительной техникой</li>
            <li>а также всем необходимым оборудованием для производства работ по берегоукреплению</li>
        </ul>
        <h2 style={{textAlign: "center"}}><em>Компания <ins>ООО СК "Пирс" имеет возможность производить полный комплект <br /> строительных и монтажных работ</ins></em>, начиная с проектирования объекта и заканчивая вводом его в эксплуатацию.</h2>
    </div>

    <div className="blockImg" ref={sectionsRefs.partners}>
        <img className="backimg" src="/img/6.png" alt="Партнёры" />
        <div className="text-overlay">
            <div className="headline">
                <h1>Наши партнёры</h1>
                <h1 style={{fontSize: "19px", fontWeight: "600", marginBottom: "5px"}}><em>ООО "Промстрой НН" г. Нижний Новгород</em></h1>
                <h2>Строительство зданий и сооружений</h2>
                <h1 style={{fontSize: "19px", fontWeight: "600", marginBottom: "5px"}}><em>АО "СУ-7 СМТ" г. Арзамас Нижегородской области</em></h1>
                <h2>Строительство магистральных нефтепроводов</h2>
                <h1 style={{fontSize: "19px", fontWeight: "600", marginBottom: "5px"}}><em>АО "Пирс" г. Дзержинск Нижегородской области</em></h1>
                <h2>Строительство берегоукрепительных и портовых сооружений</h2>
                <h1 style={{fontSize: "19px", fontWeight: "600", marginBottom: "5px"}}><em>ООО "НижегородСпецГидрострой" г. Нижний Новгород</em></h1>
                <h2 style={{fontSize: "17px"}}>Строительство берегоукрепительных сооружений и гражданское строительство</h2>
                <h1 style={{fontSize: "19px", fontWeight: "600", marginBottom: "5px"}}><em>ООО "НижновГеопроект" г. Нижний Новгород</em></h1>
                <h2>Проектирование объектов гидротехнического и транспортного назначения</h2>
            </div>
        </div>
    </div>
    </>
  )
}

export default Home;