import React from 'react'

const Contacts = () => {
  return (
    <>
      <h1 style={{margin: "0px 0px 15px 0px", textAlign: "center", fontWeight: "600", fontSize: "35px"}}>Контакты</h1>
      <div className="blockImg">
        <div className="text-overlay">
            <div className="headline">
            <h1>Общество с ограниченной ответственностью Строительная компания "Пирс"</h1>
            <h2 style={{marginBottom: "10px"}}>Адрес:</h2>
            <div style={{margin: "10px 0px"}}>
                <a>г. Нижний Новгород, ул. Памирская 11р, оф.48, 603132</a><br />
                <a>Производство: Нижегородская обл., г. Дзержинск, Речное ш., 3</a>
            </div>
            <h2 style={{marginBottom: "10px"}}>Телефоны для связи:</h2>
            <div style={{marginBottom: "10px"}}>
                <a href="tel:88313209289">8 (8313) 209 289</a><br />
                <a href="tel:+79108869546">+7 910 886 95 46</a><br />
                <a href="mailto:sk-pirs@bk.ru">sk-pirs@bk.ru</a><br />
            </div>
            <a>ИНН 5258133445 КПП 525801001 ОГРН 1165275057177</a><br />
            <a>Генеральный директор - Вагин Александр Владимирович</a>
            </div>
        </div>
        <img className="backimg" src="/img/8.png" alt="Контакты" />
      </div>
      <div className="mapscont" style={{position: "relative", overflow: "hidden", margin: "0"}}><a href="https://yandex.ru/maps/47/nizhny-novgorod/?utm_medium=mapframe&utm_source=maps" style={{color: "#eee", fontSize: "12px", position: "absolute", top: "0px"}}>Нижний Новгород</a><a href="https://yandex.ru/maps/47/nizhny-novgorod/house/pamirskaya_ulitsa_11r/YEoYfwNkSUYOQFtsfX52cXphZQ==/?ll=43.944067%2C56.270601&utm_medium=mapframe&utm_source=maps&z=16.58" style={{color: "#eee", fontSize: "12px", position: "absolute", top: "14px"}}>Памирская улица, 11Р — Яндекс Карты</a><iframe src="https://yandex.ru/map-widget/v1/?ll=43.944067%2C56.270601&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1Njk2NjcyORJQ0KDQvtGB0YHQuNGPLCDQndC40LbQvdC40Lkg0J3QvtCy0LPQvtGA0L7QtCwg0J_QsNC80LjRgNGB0LrQsNGPINGD0LvQuNGG0LAsIDEx0KAiCg2yxi9CFR0VYUI%2C&z=16.58" width="700" height="400" frameBorder="1" allowFullScreen={true} style={{position: "relative", display: "flex", justifyContent: "center", margin: "0 auto"}}></iframe></div>
    </>
  )
}

export default Contacts;