import React from 'react'
import { Link } from "react-router-dom";

function Header ({scrollToSection}) {
  return (
    <div className="header">
        <div className="logo">
            <Link to="/" onClick={() => scrollToSection("about")}><img src="/img/logoskp.png" alt="ООО СК «Пирс»"/></Link>
        </div>
        <div className="menu">
            <Link className="menuButton" to="/" onClick={() => scrollToSection("about")}>Главная</Link>
            <a className="menuButton" onClick={() => scrollToSection("about")}>О нас</a>
            <a className="menuButton" onClick={() => scrollToSection("production")}>Производство</a>
            <a className="menuButton" onClick={() => scrollToSection("projects")}>Проекты</a>
            <a className="menuButton" onClick={() => scrollToSection("equipments")}>Оборудование</a>
            <a className="menuButton" onClick={() => scrollToSection("partners")}>Партнёры</a>
        </div>
        <div className="menu-contact">
            <Link className="menuButton" to="/contacts">Контакты</Link>
        </div>
    </div>
  )
}

export default Header;