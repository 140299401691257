import React from 'react'
import { Link } from "react-router-dom";

const Footer = ({scrollToSection}) => {
  return (
    <div className="footer">
        <div className="lawinfo">
            <h1>Общество с ограниченной ответственностью Строительная компания "Пирс"</h1>
            <div style={{margin: "10px 0px"}}>
                <a>г. Нижний Новгород, ул. Памирская 11р, оф.48, 603132</a><br />
                <a>Производство: Нижегородская обл., г. Дзержинск, Речное ш., 3</a>
            </div>
            <div style={{margin: "10px 0px"}}>
                <a href="tel:88313209289">8 (8313) 209 289</a><br />
                <a href="tel:+79108869546">+7 910 886 95 46</a><br />
                <a href="mailto:sk-pirs@bk.ru">sk-pirs@bk.ru</a><br />
            </div>
            <a>ИНН 5258133445 КПП 525801001 ОГРН 1165275057177</a><br />
            <a>Генеральный директор - Вагин Александр Владимирович</a>
        </div>
        <div className="menu">
            <Link className="menuButton" to="/" onClick={() => scrollToSection("about")}>Главная</Link>
            <a className="menuButton" onClick={() => scrollToSection("about")}>О нас</a>
            <a className="menuButton" onClick={() => scrollToSection("production")}>Производство</a>
            <a className="menuButton" onClick={() => scrollToSection("projects")}>Проекты</a>
            <a className="menuButton" onClick={() => scrollToSection("equipments")}>Оборудование</a>
            <a className="menuButton" onClick={() => scrollToSection("partners")}>Партнёры</a>
        </div>
    </div>
  )
}

export default Footer;