import React from 'react'

function NotFound () {
  return (
    <>
    <div className="blockFirstHeadline">
        <h1>Страница недействительна</h1>
        <h2>Приносим извинения, запрашиваемой страницы не существует</h2>
    </div>

    <div className="blockImg">
        <img className="backimg" src="/img/bimg.png" alt="" />
        <div className="text-overlay">
            <div className="headline">
                <h1>Компания ООО СК "Пирс" - это</h1>
                <h2>Компания ООО СК "Пирс" имеет опыт работы по строительству берегоукрепительных, причальных сооружений, в промышленном гражданском строительстве.<br /> Одно из основных направлений компании, в настоящее время, является строительство портовых и берегоукрепительных сооружений, устройство свайных полей монолитных оснований подпорных стен, строительство промышленных объектов.</h2>
                <h1>Местоположение:</h1>
                <h2>Основная производственная база компании располагается в <em>г. Дзержинск Нижегородской области.</em></h2>
            </div>
        </div>
    </div>
    </>
  )
}

export default NotFound;